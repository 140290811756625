<template>
  <div>
    <vs-row id="div-with-loading" class="vs-con-loading__container">
      <vs-col vs-lg="7" vs-sm="8">
        <Accordion :items="accordionItems" open>
          <template slot="setting">
            <div class="mb-4">
              <vs-input v-model="mainIntention" label="Intenção padrão" class="w-full" />
            </div>
            <div class="flex mb-3 justify-between gap-x-8">
              <vs-select
                v-model="chatbotGoal"
                width="100%"
                label="Escolha um objetivo"
              >
                <vs-select-item
                  v-for="(item, index) in chatbotGoalOptions"
                  :key="index"
                  :value="item._id"
                  :text="item.name"
                />
              </vs-select>
              <vs-select v-model="gptChatModel" label="Modelo ChatGPT">
                <vs-select-item
                  v-for="(item, index) in gptChatModelOptions"
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                />
              </vs-select>
            </div>
            <div class="mt-4">
              <label class="vs-select--label"
                >Configuração avançadas de comportamento</label
              >
              <vs-textarea
                v-model="promptText"
                rows="21"
                placeholder="Aqui haverá um prompt padrão de acordo com o objetivo do usuário"
              />
              <p>
                <span
                  ><router-link :to="{ name: 'faq' }"
                    >Saiba mais como configurar o prompt</router-link
                  >.</span
                >
              </p>
            </div>
            <div
              v-if="$store.state.user.userRole === 'master'"
              class="flex items-center justify-between mt-5"
            >
              <div class="flex items-center">
                <label class="mr-3 text-base">Transferência para humano</label>
                <vs-switch
                  v-model="humanTransfer"
                  vs-icon-off="close"
                  vs-icon-on="done"
                />
                <vs-button
                  v-if="this.currentChatbot"
                  type="flat"
                  color="dark"
                  icon-pack="feather"
                  icon="icon-external-link"
                  radius
                  @click="handleFunctionsPopup('humanTransfer')"
                  class="ml-1"
                  size="small"
                />
              </div>
              <div class="flex items-center">
                <label class="mr-3 text-base">Encerrar conversa</label>
                <vs-switch
                  v-model="closeConversation"
                  vs-icon-off="close"
                  vs-icon-on="done"
                />
                <vs-button
                  v-if="this.currentChatbot"
                  type="flat"
                  color="dark"
                  icon-pack="feather"
                  icon="icon-external-link"
                  class="ml-1"
                  @click="handleFunctionsPopup('closeConversation')"
                  size="small"
                  radius
                />
              </div>
              <div class="flex items-center">
                <label class="mr-3 text-base">Adicionar Tag</label>
                <vs-switch
                  v-model="addTag"
                  vs-icon-off="close"
                  vs-icon-on="done"
                />
                <vs-button
                  v-if="this.currentChatbot"
                  type="flat"
                  color="dark"
                  icon-pack="feather"
                  icon="icon-external-link"
                  class="ml-1"
                  radius
                  size="small"
                  @click="handleFunctionsPopup('addTag')"
                />
              </div>
            </div>
          </template>
          <template slot="knowledgeBase">
            <vs-table
              stripe
              :hoverFlat="true"
              :data="rowData"
              class="w-full"
              pagination
              max-items="10"
              :noDataText="$t('EmptyTable')"
            >
              <template v-slot:thead>
                <vs-th>Conteúdo</vs-th>
                <vs-th>Tipo</vs-th>
                <vs-th>Status</vs-th>
                <vs-th class="flex justify-center">Ações</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="tr.name">
                    {{ tr.name }}
                  </vs-td>
                  <vs-td :data="tr.type">
                    {{ tr.type === "File" ? "Arquivo" : "Site" }}
                  </vs-td>
                  <vs-td :data="tr.status">
                    <vs-chip
                      transparent
                      :color="
                        tr.status === 'Error'
                          ? 'danger'
                          : tr.status === 'Treinando'
                          ? 'primary'
                          : tr.status === 'Treinado'
                          ? 'success'
                          : 'dark'
                      "
                    >
                      {{
                        tr.status === "Error"
                          ? "ERRO"
                          : tr.status === "Treinando"
                          ? "TREINANDO"
                          : tr.status === "Treinado"
                          ? "TREINADO"
                          : "NÃO TREINADO"
                      }}
                    </vs-chip>
                  </vs-td>
                  <vs-td>
                    <div class="flex justify-end">
                      <vs-tooltip
                        v-if="tr.type === 'Url'"
                        class="self-center mr-3 mt-2"
                        :text="`Atualizado em ${formatDate(
                          tr.updatedAt
                        )} (Atualização diária)`"
                      >
                        <vs-icon
                          icon-pack="feather"
                          icon="icon-info"
                          class="text-grey"
                        >
                        </vs-icon>
                      </vs-tooltip>
                      <vs-button
                        v-if="tr.type !== 'Url'"
                        type="flat"
                        color="dark"
                        icon-pack="feather"
                        icon="icon-download"
                        class="mt-1"
                        target
                        :href="tr.url"
                      />
                      <vs-button
                        v-else
                        type="flat"
                        color="dark"
                        icon-pack="feather"
                        icon="icon-external-link"
                        class="mt-1"
                        target
                        :href="tr.name"
                      />
                      <vs-button
                        type="flat"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-trash"
                        class="mt-1"
                        @click="openPopupDeleteFile(tr)"
                      />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <vs-button
              icon-pack="feather"
              icon="icon-upload"
              icon-after
              class="w-full"
              @click="popupUpload = true"
            >
              Adicionar Conteúdo
            </vs-button>
            <vs-button
              color="success"
              icon-pack="feather"
              icon="icon-save"
              icon-after
              class="w-full mt-3"
              @click="saveGoalToChat"
            >
              {{ $t("Save") }}
            </vs-button>
          </template>
          <template slot="intentionsKeyEntity">
            <div class="flex items-center justify-between">
              <div class="flex gap-x-2">
                <vs-button
                  class="rounded-xxl"
                  size="small"
                  :type="type === 'all' ? 'filled' : 'border'"
                  @click="type = 'all'"
                  >Todos</vs-button
                >
                <vs-button
                  class="rounded-xxl"
                  size="small"
                  :type="type === 'intention' ? 'filled' : 'border'"
                  @click="type = 'intention'"
                  >Intenções</vs-button
                >
                <vs-button
                  class="rounded-xxl"
                  size="small"
                  :type="type === 'entities' ? 'filled' : 'border'"
                  @click="type = 'entities'"
                  >Entidade</vs-button
                >
                <vs-button
                  class="rounded-xxl"
                  size="small"
                  :type="type === 'keyEntities' ? 'filled' : 'border'"
                  @click="type = 'keyEntities'"
                  >Entidades-chave</vs-button
                >
              </div>
              <vs-button
                icon-pack="feather"
                icon="icon-plus"
                icon-after
                size="small"
                @click="popupIntentionEntitiesKey('', '', -1)"
              >
                Adicionar
              </vs-button>
            </div>
            <vs-table
              hoverFlat
              stripe
              :data="intentionKeyEntitiesTable"
              :noDataText="$t('EmptyTable')"
              class="mt-5 intentionTable"
              :notSpacer="true"
            >
              <template slot="thead">
                <vs-th>Conteúdo</vs-th>
                <vs-th>Tipo</vs-th>
                <vs-th>{{ $t("Actions") }}</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="tr.name">
                    {{ tr.name }}
                  </vs-td>
                  <vs-td :data="tr.type">
                    {{ nameType(tr.type) }}
                  </vs-td>
                  <vs-td :data="tr.actions">
                    <div class="flex items-center gap-x-2">
                      <vs-button
                        type="flat"
                        color="warning"
                        icon-pack="feather"
                        icon="icon-edit"
                        radius
                        @click="
                          popupIntentionEntitiesKey(tr.type, tr.name, indextr)
                        "
                      />
                      <vs-button
                        type="flat"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-trash"
                        radius
                        @click="
                          popupDeleteIntentionEntitiesKey(
                            tr.type,
                            tr.name,
                            indextr
                          )
                        "
                      />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </template>
        </Accordion>
        <vs-button
          class="mt-5 vs-con-loading__container float-right"
          icon-pack="feather"
          icon="icon-save"
          icon-after
          id="loadableButton"
          color="success"
          @click="saveGoalToChat()"
        >
          {{ $t("Save") }}
        </vs-button>
        <vs-button
          @click="$router.back()"
          type="border"
          class="mt-5 vs-con-loading__container float-right mr-3"
          icon-pack="feather"
          icon="icon-arrow-left"
          id="loadableButton"
        >
          {{ $t("Back") }}
        </vs-button>
      </vs-col>
      <vs-col
        v-if="currentChatbot !== null"
        vs-lg="5"
        vs-sm="4"
        vs-type="flex"
        vs-justify="center"
        style="min-height: 670px"
      >
        <ChatAIPreview :chatbot="currentChatbot" v-if="currentChatbot" />
      </vs-col>
    </vs-row>

    <vs-popup
      :title="
        intentionKeyEntitiesToEdit.name === ''
          ? 'Adicionar intenção, entidade ou entidade-chave'
          : 'Editar ' + nameType(intentionKeyEntitiesToEdit.type).toLowerCase()
      "
      :active.sync="newOrEditIntentionEntityKeyPopup"
    >
      <div>
        <vs-radio
          v-model="intentionKeyEntitiesToEdit.type"
          vs-name="type"
          vs-value="intention"
          >{{ $t("Intention") }}</vs-radio
        >
        <vs-radio
          v-model="intentionKeyEntitiesToEdit.type"
          vs-name="type"
          vs-value="keyEntities"
          class="ml-6"
          >{{ $t("EntityKey") }}</vs-radio
        >
        <vs-radio
          v-model="intentionKeyEntitiesToEdit.type"
          vs-name="type"
          vs-value="entities"
          class="ml-6"
          >{{ $t("Entity") }}</vs-radio
        >
      </div>
      <div class="mt-3">
        <vs-input
          autofocus
          v-model="intentionKeyEntitiesToEdit.name"
          name="intention"
          type="text"
          label="Conteúdo"
          class="w-full"
        />
      </div>
      <vs-button
        :disabled="intentionKeyEntitiesToEdit.name.trim().length === 0"
        @click="addOrUpdateIntentionKeyEntity"
        icon-pack="feather"
        :icon="intentionKeyEntitiesIndex >= 0 ? 'icon-edit-2' : 'icon-plus'"
        class="float-right mt-6"
      >
        {{ $t(intentionKeyEntitiesIndex >= 0 ? "Edit" : "Add") }}
      </vs-button>
    </vs-popup>

    <vs-popup
      :title="
        'Deletar ' + nameType(intentionKeyEntitiesToDelete.type).toLowerCase()
      "
      :active.sync="deleteIntentionEntitiesKey"
    >
      <vs-row>
        <vs-col>
          <h5 class="text-center">
            Tem certeza que deseja remover a
            {{ nameType(intentionKeyEntitiesToDelete.type).toLowerCase() }} -
            <b>{{ intentionKeyEntitiesToDelete.name }}</b>
          </h5>
        </vs-col>
        <vs-col class="mt-5" vs-type="flex" vs-justify="end">
          <vs-button
            color="danger"
            icon="icon-trash"
            icon-pack="feather"
            icon-after
            @click="deleteIntentionKeyEntity"
            class="px-2"
          >
            {{ $t("Confirm") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup :title="popupEditFunctionTitle" :active.sync="popupEditFunction">
      <vs-row>
        <vs-textarea
          :spellcheck="false"
          v-model="popupEditFunctionValue"
          rows="15"
          class="w-full mt-2 font-bold"
        />
        <vs-button
          class="w-full"
          color="success"
          :disabled="popupEditFunctionValue.trim() === ''"
          @click="handleSaveFunction"
        >
          {{ $t("Save") }}
        </vs-button>
      </vs-row>
    </vs-popup>

    <vs-popup title="Adicionar conteúdo" :active.sync="popupUpload">
      <vs-row vs-type="flex" vs-justify="space-around">
        <vs-col vs-w="5">
          <div
            class="border-solid border rounded-md p-4 flex items-center h-16 cursor-pointer hover:border-primary"
            @click="typeUpload = 'file'"
            :class="[typeUpload === 'file' ? 'border-primary' : 'border-grey']"
          >
            <vs-radio v-model="typeUpload" vs-name="radios1" vs-value="file"
              >Arquivo</vs-radio
            >
          </div>
        </vs-col>
        <vs-col vs-w="5">
          <div
            class="border-solid border rounded-md p-4 flex items-center h-16 cursor-pointer hover:border-primary"
            @click="typeUpload = 'site'"
            :class="[typeUpload === 'site' ? 'border-primary' : 'border-grey']"
          >
            <vs-radio v-model="typeUpload" vs-name="radios1" vs-value="site"
              >Site</vs-radio
            >
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="mt-5" v-if="typeUpload === 'file'">
          <p class="text-base text-center">
            Formatos suportados: pdf, txt, doc, docx.<br />
            Limite de 100 arquivos com 50mb cada
          </p>
        </vs-col>
        <vs-col class="mt-5" v-if="typeUpload === 'file'">
          <custom-tag
            v-for="(item, index) in filesToUpload"
            :key="index"
            :label="item.name || item"
            color="grey"
            remove
            @remove="removeFile(index)"
            :class="{ 'mt-3': index > 0 }"
            icon="FileTextIcon"
          />
        </vs-col>
        <vs-col class="mt-5" v-else>
          <custom-tag
            v-for="(item, index) in sitesToUpload"
            :key="index"
            :label="item.name || item"
            color="grey"
            remove
            @remove="removeSite(index)"
            :class="{ 'mt-3': index > 0 }"
            icon="LinkIcon"
          />
        </vs-col>
        <vs-col class="mt-3" vs-w="12" v-if="typeUpload === 'file'">
          <input
            type="file"
            class="hidden"
            ref="fileUpload"
            accept=".pdf, .txt, .doc, .docx"
            multiple
            v-on:input="handleFilesBeforeUpload"
          />
          <vs-button
            icon-pack="feather"
            icon="icon-upload"
            icon-after
            :disabled="disableButtons"
            @click="$refs.fileUpload.click()"
            class="w-full"
          >
            Adicionar arquivos
          </vs-button>
        </vs-col>
        <vs-col class="mt-5" vs-w="9" v-else>
          <vs-input
            v-model="site"
            placeholder="Inserir URL do site"
            type="url"
            icon-pack="feather"
            icon="icon-globe"
            class="w-12/12"
            :danger="!isUrlValid && site.length > 0"
            danger-text="Url inválida."
          />
        </vs-col>
        <vs-col class="mt-5" vs-w="3" v-if="typeUpload === 'site'">
          <vs-button
            icon-pack="feather"
            icon="icon-plus"
            icon-after
            @click="
              sitesToUpload.push(site);
              site = '';
            "
            :disabled="
              site.length === 0 || !isUrlValid || sitesToUpload.length >= 100
            "
          >
            Adicionar
          </vs-button>
        </vs-col>
        <vs-col class="mt-5" vs-w="12">
          <vs-button
            icon-pack="feather"
            icon="icon-save"
            icon-after
            class="w-full"
            color="success"
            @click="handleUpload()"
            :disabled="typeUpload === 'site' && sitesToUpload.length === 0"
          >
            {{ $t("Save") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup title="Deletar conteúdo" :active.sync="popupDeleteFile">
      <vs-row>
        <vs-col>
          <h5 class="text-center">
            Tem certeza que deseja remover o conteúdo - {{ fileToDelete.name }}
          </h5>
        </vs-col>
        <vs-col class="mt-5" vs-type="flex" vs-justify="end">
          <vs-button
            color="danger"
            icon="icon-trash"
            icon-pack="feather"
            icon-after
            @click="deleteFile(fileToDelete._id)"
            class="px-2"
          >
            {{ $t("Remove") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import CustomTag from "@/components/CustomTag.vue";
import axios from "../../../http/axios";
import moment from "moment";
import ChatAIPreview from "@/components/duotalk-ia-settings/ChatAIPreview.vue";
import Accordion from "@/components/Accordion.vue";

export default {
  name: "ChatbotAISettings",
  components: { CustomTag, ChatAIPreview, Accordion },
  data() {
    return {
      mainIntention: "Atendimento IA",
      type: "all",
      popupDeleteIntention: false,
      intentionKeyEntitiesIndex: -1,
      iframeKey: 1,
      newOrEditIntentionEntityKeyPopup: false,
      popupEditFunction: false,
      popupEditFunctionType: null,
      popupEditFunctionTitle: "",
      popupEditFunctionValue: "",
      aiAddTagDefault: `{
  "description": "Determine alguma tag para o usuário quando pegar alguma informação importante como empresa, cargo, email, telefone",
  "parameters": {
    "type": "object",
    "properties": {
      "tag": {
        "type": "string",
        "description": "exemplo de tag 'empresa: valor', 'email: valor' sempre com chave: valor"
      }
    },
    "required": [
      "tag"
    ]
  }
}`,
      aiFunctionCloseConversationDefault: `{
  "description": "Execute essa função quando o usuário nao quiser mais conversar",
  "parameters": {
    "type": "object",
    "properties": {},
    "required": []
  }
}`,
      aiFunctionForwardHumanDefault: `{
  "description": "Se a pessoa quiser mais informações ou informações que voce nao possua determine o setor para a pessoa: -vendas -suporte",
  "parameters": {
      "type": "object",
      "properties": {
          "setor": {
              "type": "string",
              "description": "vendas ou suporte"
          }
      },
      "required": ["setor"]
  }
}`,
      currentChatbot: null,
      chatbotGoal: "",
      chatbotGoalOptions: [],
      goal: null,
      promptText: "",
      popupUpload: false,
      typeUpload: "file",
      filesToUpload: [],
      popupDeleteFile: false,
      fileToDelete: {},
      site: "",
      sitesToUpload: [],
      addTag: false,
      humanTransfer: false,
      closeConversation: false,
      gptChatModel: "gpt-4o-mini",
      gptChatModelOptions: [
        { value: "gpt-3.5-turbo", text: "GPT-3.5 Turbo" },
        { value: "gpt-4o", text: "GPT-4o" },
        { value: "gpt-4o-mini", text: "GPT-4o Mini" },
      ],
      intentionsKeyEntities: [],
      intentionKeyEntitiesToEdit: {
        type: "intention",
        name: "",
      },
      intentionKeyEntitiesToDelete: {
        type: "intention",
        name: "",
      },
      deleteIntentionEntitiesKey: false,
      accordionItems: [
        { id: "setting", title: "Configurar funcionamento" },
        { id: "knowledgeBase", title: "Base de conhecimento" },
        { id: "intentionsKeyEntity", title: "Intenções / Entidades-chave" },
      ],
    };
  },
  async mounted() {
    await this.fetchChatbot();
    await this.getGoals();
    await this.listFiles();

    if (this.currentChatbot.id && this.currentChatbot.objective !== undefined) {
      this.chatbotGoal = this.currentChatbot.objective;
    } else {
      this.chatbotGoal = this.chatbotGoalOptions[0]._id;
      await this.getGoal(this.chatbotGoal);
    }

    this.humanTransfer = this.currentChatbot.aiForwardConversationFn;
    this.closeConversation = this.currentChatbot.aiCloseConversationFn;
    this.addTag = this.currentChatbot.aiAddTagFn;
    this.gptChatModel = this.currentChatbot.aiOpenaiModel;
  },
  computed: {
    rowData() {
      return this.$store.state.acc.aiFiles;
    },
    disableButtons() {
      return this.totalFiles >= this.filesLimit;
    },
    totalFiles() {
      return this.rowData.length + this.filesToUpload.length;
    },
    isUrlValid() {
      if (!this.site) return false;

      const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator

      return urlPattern.test(this.site);
    },
    intentionKeyEntitiesTable() {
      if (this.type === "all") return this.intentionsKeyEntities;
      else
        return this.intentionsKeyEntities.filter(
          (item) => item.type === this.type
        );
    },
  },
  methods: {
    addOrUpdateIntentionKeyEntity() {
      if (!this.intentionKeyEntitiesToEdit.name) return;

      if (
        this.intentionsKeyEntities.find(
          (i) =>
            i.name.toLowerCase() ===
            this.intentionKeyEntitiesToEdit.name.toLowerCase()
        )
      ) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text:
            (this.intentionKeyEntitiesToEdit.type === "intention"
              ? "Intenção"
              : "Entidade-chave") + " já cadastrada",
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "warning",
          position: "top-right",
        });
        return;
      }

      if (this.intentionKeyEntitiesIndex === -1) {
        if (this.intentionKeyEntitiesToEdit.type === "entities")
          this.intentionKeyEntitiesToEdit.name =
            "@" + this.intentionKeyEntitiesToEdit.name;
        this.intentionsKeyEntities.push(this.intentionKeyEntitiesToEdit);
      } else {
        this.intentionsKeyEntities[this.intentionKeyEntitiesIndex].name =
          this.intentionKeyEntitiesToEdit.name;
        this.intentionsKeyEntities[this.intentionKeyEntitiesIndex].type =
          this.intentionKeyEntitiesToEdit.type;
      }

      this.newOrEditIntentionEntityKeyPopup = false;
    },
    deleteIntentionKeyEntity() {
      this.intentionsKeyEntities.splice(this.intentionKeyEntitiesIndex, 1);
      this.deleteIntentionEntitiesKey = false;
    },
    popupIntentionEntitiesKey(type, name, index) {
      this.newOrEditIntentionEntityKeyPopup = true;
      if (type === "" && name === "") {
        this.intentionKeyEntitiesIndex = -1;
        this.intentionKeyEntitiesToEdit = {
          type: "intention",
          name: "",
        };
      } else {
        this.intentionKeyEntitiesIndex = index;
        this.intentionKeyEntitiesToEdit = { type, name };
      }
    },
    popupDeleteIntentionEntitiesKey(type, name, index) {
      this.intentionKeyEntitiesToDelete.name = name;
      this.intentionKeyEntitiesToDelete.type = type;
      this.intentionKeyEntitiesIndex = index;
      this.deleteIntentionEntitiesKey = true;
    },
    nameType(type) {
      let name = "";

      switch (type) {
        case "intention":
          name = this.$t("Intention");
          break;
        case "entities":
          name = this.$t("Entity");
          break;
        case "keyEntities":
          name = this.$t("KeyEntities");
          break;
      }

      return name;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    handleFunctionsPopup(type) {
      this.popupEditFunctionType = type;

      switch (type) {
        case "humanTransfer":
          this.popupEditFunctionTitle = "Transferência para humano";
          this.popupEditFunctionValue =
            this.currentChatbot.aiForwardConversationFnValue ||
            this.aiFunctionForwardHumanDefault;
          break;
        case "closeConversation":
          this.popupEditFunctionTitle = "Encerrar conversa";
          this.popupEditFunctionValue =
            this.currentChatbot.aiCloseConversationFnValue ||
            this.aiFunctionCloseConversationDefault;
          break;
        case "addTag":
          this.popupEditFunctionTitle = "Adicionar tag";
          this.popupEditFunctionValue =
            this.currentChatbot.aiAddTagFnValue || this.aiAddTagDefault;
          break;
      }

      this.popupEditFunction = true;
    },
    async handleSaveFunction() {
      const data = {
        aiCloseConversationFn: this.closeConversation,
        aiForwardConversationFn: this.humanTransfer,
        aiAddTagFn: this.addTag,
      };

      switch (this.popupEditFunctionType) {
        case "humanTransfer":
          data.aiForwardConversationFnValue = this.popupEditFunctionValue;
          break;
        case "closeConversation":
          data.aiCloseConversationFnValue = this.popupEditFunctionValue;
          break;
        case "addTag":
          data.aiAddTagFnValue = this.popupEditFunctionValue;
          break;
      }

      this.$vs.loading();

      try {
        const res = await this.$http.post(
          "/p/chat/assistant/chat/" + this.$route.params.chatbot_id + "/config",
          data
        );

        this.currentChatbot = res.data.data;

        this.popupEditFunction = false;
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError") + " - " + e.response.data.message,
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "danger",
          position: "top-right",
        });
      }

      this.$vs.loading.close();
    },
    async fetchChatbot() {
      const res = await axios.get(`/p/chat/${this.$route.params.chatbot_id}`);

      this.iframeKey++;
      this.currentChatbot = res.data.data;

      this.mainIntention = this.currentChatbot.aiMainIntention;

      if (this.currentChatbot.intentions.length > 0)
        this.currentChatbot.intentions.map((item) => {
          this.intentionsKeyEntities.push({
            name: item,
            type: "intention",
          });
        });
      if (this.currentChatbot.keyEntities.length > 0)
        this.currentChatbot.keyEntities.map((item) => {
          this.intentionsKeyEntities.push({
            name: item,
            type: "keyEntities",
          });
        });
      if (this.currentChatbot.entities.length > 0)
        this.currentChatbot.entities.map((item) => {
          this.intentionsKeyEntities.push({
            name: item,
            type: "entities",
          });
        });
    },
    async listFiles() {
      try {
        const response = await this.$http.get(
          "/p/chat/ai/assistant/files?acc=" +
            this.$store.state.acc.current_acc.id +
            "&chat=" +
            this.$route.params.chatbot_id
        );
        await this.$store.dispatch("acc/setAiFiles", response.data.data);
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError") + " - " + e.response.data.message,
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
    handleFilesBeforeUpload(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      files.forEach((file) => this.filesToUpload.push(file));
    },
    removeFile(index) {
      let arrayFile = Array.from(this.filesToUpload);
      arrayFile.splice(index, 1);

      this.filesToUpload = arrayFile;
    },
    removeSite(index) {
      let arraySite = Array.from(this.sitesToUpload);
      arraySite.splice(index, 1);

      this.sitesToUpload = arraySite;
    },
    async handleUpload() {
      this.popupUpload = false;
      this.$vs.loading();

      let fd = new FormData();

      if (this.typeUpload === "file") {
        this.filesToUpload.forEach((item, i) => {
          fd.append("files" + i, item);
        });
      } else {
        this.sitesToUpload.forEach((item, i) => {
          fd.append("url" + i, item);
        });
      }

      try {
        const res = await this.$http.post(
          "/p/chat/ai/assistant/files/save?",
          fd,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.auth.accessToken,
              "Content-Type": "multipart/form-data",
            },
            params: {
              acc: this.$store.state.acc.current_acc.id,
              chat: this.$route.params.chatbot_id,
            },
          }
        );

        this.$vs.notify({
          time: 2500,
          title: this.$t("Success"),
          text: "Conteúdo adicionado com sucesso.",
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
          position: "top-right",
        });

        this.filesToUpload = [];

        await this.$store.dispatch("acc/setAiFiles", [
          ...this.rowData,
          ...res.data.data,
        ]);
      } catch (e) {
        this.$vs.notify({
          time: 4000,
          title: this.$t("Error"),
          text: e.response.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
      }
      this.$vs.loading.close();
    },
    async getGoals() {
      const response = await this.$http.get("/p/chat/assistant/objective/list");

      if (response.status === 200) {
        this.chatbotGoalOptions = response.data.data;
      }
    },
    async getGoal(id) {
      const response = await this.$http.get(
        "/p/chat/assistant/objective/" + id
      );

      if (response.status === 200) {
        this.goal = response.data.data;

        if (
          this.currentChatbot.objectiveInstruction !== "" &&
          this.currentChatbot.objectiveInstruction !== undefined
        ) {
          this.promptText = this.currentChatbot.objectiveInstruction;
        } else {
          this.promptText = response.data.data.instruction;
        }
      }
    },
    async saveGoalToChat() {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.6,
      });
      try {
        const responseGoals = await this.$http.post(
          "/p/chat/assistant/objective/save/chat/" +
            this.$route.params.chatbot_id,
          {
            objective: this.goal._id,
            objectiveInstruction: this.promptText,
          }
        );
        const responseSettings = await this.$http.post(
          "/p/chat/assistant/chat/" + this.$route.params.chatbot_id + "/config",
          {
            aiMainIntention: this.mainIntention,
            aiCloseConversationFn: this.closeConversation,
            aiForwardConversationFn: this.humanTransfer,
            aiAddTagFn: this.addTag,
            aiOpenaiModel: this.gptChatModel,
            intentions:
              this.intentionsKeyEntities.filter(
                (item) => item.type === "intention"
              ).length <= 0
                ? null
                : this.intentionsKeyEntities
                    .filter((item) => item.type === "intention")
                    .map((item) => {
                      return item.name;
                    }),
            keyEntities:
              this.intentionsKeyEntities.filter(
                (item) => item.type === "keyEntities"
              ).length <= 0
                ? null
                : this.intentionsKeyEntities
                    .filter((item) => item.type === "keyEntities")
                    .map((item) => {
                      return item.name;
                    }),
            entities:
              this.intentionsKeyEntities.filter(
                (item) => item.type === "entities"
              ).length <= 0
                ? null
                : this.intentionsKeyEntities
                    .filter((item) => item.type === "entities")
                    .map((item) => {
                      return item.name;
                    }),
          }
        );
        if (responseGoals.status === 200 && responseSettings.status === 200) {
          this.$vs.notify({
            title: this.$t("Success"),
            text: "Chatbot configurado com sucesso.",
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
            position: "top-right",
            time: 4000,
          });

          localStorage.removeItem("settings");
          localStorage.removeItem("isChatOpen");
          localStorage.removeItem("sessionID");
        }
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
      } catch (err) {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
      }
    },
    openPopupDeleteFile(item) {
      this.fileToDelete = item;
      this.popupDeleteFile = true;
    },
    async deleteFile(id) {
      try {
        this.popupDeleteFile = false;

        const response = await this.$http.delete(
          "/p/chat/ai/assistant/files/delete/" +
            id +
            "?acc=" +
            this.$store.state.acc.current_acc.id +
            "&chat=" +
            this.$route.params.chatbot_id
        );

        await this.$store.dispatch("acc/removeAiFileById", id);

        this.$vs.notify({
          time: 2500,
          title: this.$t("Success"),
          text: response.data.message,
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
          position: "top-right",
        });
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError") + " - " + e.response.data.message,
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
  },
  watch: {
    popupEditFunction(val) {
      if (val === false) {
        this.popupEditFunctionTitle = "";
        this.popupEditFunctionValue = "";
        this.popupEditFunctionType = "";
      }
    },
    async chatbotGoal() {
      await this.getGoal(this.chatbotGoal);
    },
  },
};
</script>
<style lang="scss">
.vs-collapse {
  padding: 0 !important;
}
.con-content--item,
.vs-collapse-item--header {
  background: #fff !important;
}
.intentionTable table tr th:last-child {
  width: 100px;

  .vs-table-text {
    text-align: center;
    display: block;
  }
}
</style>
