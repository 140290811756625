<template>
  <div class="vs-collapse margin">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="vs-collapse-item"
    >
      <!-- Cabeçalho do Accordion -->
      <button
        @click="toggle(index)"
        :class="{'border-b-default border-t-0 border-l-0 border-r-0 rounded-b-none border-grey-light': activeIndex === index, 'border-none': activeIndex !== index}"
        class="vs-collapse-item--header flex justify-between items-center w-full text-left text-gray-700 font-medium focus:outline-none rounded-md outline-none"
      >
        <span>{{ item.title }}</span>
        <svg
          :class="{'rotate-180': activeIndex === index}"
          class="h-5 w-5 transform transition-transform duration-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      <!-- Conteúdo do Accordion -->
      <div
        v-show="activeIndex === index"
        :class="{'h-0 overflow-hidden': activeIndex !== index}"
        class="vs-collapse-item--content"
      >
        <div class="con-content--item opacity-100">
          <slot :name="item.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        required: true,
      },
      open: {
        type: Boolean,
        required: false,
        default: false,
      }
    },
    data() {
      return {
        activeIndex: this.open ? 0 : null,
      };
    },
    methods: {
      toggle(index) {
        this.activeIndex = this.activeIndex === index ? null : index;
      },
    },
  }
</script>
